"use client";
import { Box, DepartureItem, Section } from "@hkexpressairwayslimited/ui";
import { closeLoadingBackdrop } from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import { cancelInitNskToken } from "modules/features/auth/actions/nskTokenAction";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { storeCurrencyDetail } from "store/sessionStorage/slices/currency";
// import { resetFlightBooking } from "store/sessionStorage/slices/flightBookingSlice";
// import { resetMMBState } from "store/sessionStorage/slices/manageMyBookingSlice";
import { resetNskToken } from "store/sessionStorage/slices/nskTokenSlice";
import { BookingBox } from "ui/features/flight-booking/booking/BookingBox";
const { resetFlightBooking } = require("store/sessionStorage/slices/flightBookingSlice");
const { resetMMBState } = require("store/sessionStorage/slices/manageMyBookingSlice");

function Page() {
  const dispatch = useDispatch();
  dispatch(closeLoadingBackdrop());

  useEffect(() => {
    dispatch(cancelInitNskToken());
    dispatch(resetNskToken());
    dispatch(resetFlightBooking());
    dispatch(resetMMBState());
  }, []);

  const handleDepartureChange = useCallback(
    (data: DepartureItem) => {
      dispatch(
        storeCurrencyDetail({
          selectedCurrency: data.market,
          bookingCurrency: data.market,
        })
      );
    },
    [dispatch]
  );
  return (
    <Section>
      <Box sx={{ mt: "50px", mb: "300px" }}>
        <BookingBox onDepartureChange={handleDepartureChange} />
      </Box>
    </Section>
  );
}

export default Page;
