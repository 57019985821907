import { useTransContent } from "modules/common/trans-content/transContent";
import { useMemo } from "react";

type AirportCodeMap = {
  [key: string]: string;
};

export default function useBookTripSelect({ portsCode, salesPort }: { portsCode?: string[]; salesPort?: string[] }) {
  const { t } = useTransContent();
  const airportCode: AirportCodeMap = useMemo(() => {
    const portNames =
      portsCode?.reduce((acc, port) => {
        acc[port] = t(`airportCodeToCityName.${port}`) as string;
        return acc;
      }, {} as AirportCodeMap) || {};

    const marketNames =
      salesPort?.reduce((acc, market) => {
        acc[market] = t(`marketCodeName.${market}`) as string;
        return acc;
      }, {} as AirportCodeMap) || {};

    return { ...portNames, ...marketNames };
  }, [portsCode, salesPort, t]);
  return useMemo(
    () => ({
      trip: t("web.home.bookATrip.trip"),
      passengers: t("web.home.bookATrip.passengers"),
      passengersAdult: t("web.home.bookATrip.passengers.adults"),
      passengersChild: t("web.home.bookATrip.passengers.children"),
      passengersChildTip: t("web.home.bookATrip.passengers.childTip"),
      passengersChildToolTip: t("web.home.bookATrip.passengers.childToolTip"),
      passengersInfant: t("web.home.bookATrip.passengers.infants"),
      passengersInfantToolTip: t("web.home.bookATrip.passengers.infantToolTip"),
      passengersInfantTip: t("web.home.bookATrip.passengers.infantTip"),
      passengersPopUpTitle: t("web.home.bookATrip.passengers.popUpTitle"),
      promoCodeOptional: t("web.home.bookATrip.promoCodeOptional"),
      date: t("web.home.bookATrip.date"),
      chooseTravelDates: t("web.home.bookATrip.chooseTravelDates"),
      departDate: t("web.home.bookATrip.departDate"),
      returnDate: t("web.home.bookATrip.returnDate"),
      departDateTrip1: t("web.home.bookATrip.departDateTrip1"),
      departDateTrip2: t("web.home.bookATrip.departDateTrip2"),
      search: t("web.home.bookATrip.search"),
      recentSearches: t("web.home.bookATrip.recentSearches"),
      multiCity: t("web.home.bookATrip.multiCity"),
      tripOptions: {
        roundTrip: t("web.home.bookATrip.roundTrip"),
        oneWay: t("web.home.bookATrip.oneWay"),
        multiCity: t("web.home.bookATrip.multiCity"),
      },
      fromRequired: t("web.home.bookATrip.from.required"),
      toRequired: t("web.home.bookATrip.to.toRequired"),
      dateRequired: t("web.home.bookATrip.dateRequired"),
      departDateRequired: t("web.home.bookATrip.departDate.required"),
      returnDateRequired: t("web.home.bookATrip.returnDate.required"),
      multiCityFrom1Required: t("web.home.bookATrip.multiCityFrom1.required"),
      multiCityTo1Required: t("web.home.bookATrip.multiCityTo1.required"),
      multiCityDepartDateRequired: t("web.home.bookATrip.multiCityDepartDate.required"),
      multiCityFrom2Required: t("web.home.bookATrip.multiCityFrom2.required"),
      multiCityTo2Required: t("web.home.bookATrip.multiCityTo2.required"),
      multiCityReturnDateRequired: t("web.home.bookATrip.multiCityReturnDate.required"),
      dateErrorMessage: t("web.home.bookATrip.dateErrorMessage"),
      from: t("web.home.bookATrip.from"),
      to: t("web.flightBook.destination"),
      airportCode: airportCode,
    }),
    [airportCode, t]
  );
}
